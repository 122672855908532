import React from "react";
import { Content } from "../Content";
import { Header } from "../Header";

export const App = () => {

  return (
    <>
      <Header />
      <Content />
    </>
  );
};
